<template>
    <card>
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('teaGardenBtriService.payment_for') }}</h4>
      </template>
      <template v-slot:searchBody>
        <b-overlay :show="loading"></b-overlay>
      </template>
      </card>
  </template>
  <script>
  import RestApi, { lrcpnServiceBaseUrl } from '@/config/api_config'
  import { paymentOnlineDecline } from '../../../api/routes'
  export default {
    name: 'Form',
    props: ['item'],
      components: {},
    data () {
      return {
        valid: null,
        saveBtnName: this.$route.query.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
        errors: [],
        circularLoading: false,
        data: {},
        isGardenAdmin: false,
        loading: false
      }
    },
    created () {
      if (this.$route.query.transId) {
        this.loading = true
        const tmp = this.getData(this.$route.query.transId)
        this.data = tmp
      }
    },
    computed: {
      currentLocale () {
          return this.$i18n.locale
      }
    },
    methods: {
      async getData (transId) {
          let result = null
          result = await RestApi.getData(lrcpnServiceBaseUrl, paymentOnlineDecline, { transId: transId })
          if (result.success) {
            this.$router.push({ name: 'lrcpn_service_panel.formalin_import_license_application' })
              this.$toast.success({
                title: 'Success',
                message: this.$t('teaGardenBtriService.payment_decline')
              })
            }
          this.data = result.data
          this.loading = false
      }
    },
    watch: {
      currentLocale: function () { }
    }
  }
  </script>
  <style scoped>
    .p_heading{
      font-weight: bold;
    }
  </style>
